import { useEffect, useState } from 'react';
import { GitBranch, Star, GitCommit, GitPullRequest, ExternalLink } from 'lucide-react';

interface GitHubStats {
  repoCount: number;
  totalStars: number;
  contributions: number;
  pullRequests: number;
  topLanguages: Array<{
    name: string;
    percentage: number;
    color: string;
  }>;
  topRepos: Array<{
    name: string;
    stars: number;
    url: string;
  }>;
}

const GitHubMetrics = () => {
  const [stats, setStats] = useState<GitHubStats | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const username = import.meta.env.VITE_GITHUB_USERNAME;
  const token = import.meta.env.VITE_GITHUB_TOKEN;

  useEffect(() => {
    const fetchGitHubStats = async () => {
      try {
        setLoading(true);
        setError(null);

        const headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        };

        // Fetch user's repositories
        const reposResponse = await fetch(
          `https://api.github.com/users/${username}/repos?per_page=100`,
          { headers }
        );
        
        if (!reposResponse.ok) {
          throw new Error('Failed to fetch repositories');
        }

        const repos = await reposResponse.json();

        // Calculate basic stats
        const totalStars = repos.reduce((acc: number, repo: any) => acc + repo.stargazers_count, 0);
        const languages = new Map();
        
        // Fetch languages for each repo
        await Promise.all(
          repos.slice(0, 10).map(async (repo: any) => { // Limit to top 10 repos for performance
            const langResponse = await fetch(repo.languages_url, { headers });
            const langData = await langResponse.json();
            
            Object.entries(langData).forEach(([lang, bytes]: [string, any]) => {
              languages.set(lang, (languages.get(lang) || 0) + bytes);
            });
          })
        );

        // Calculate language percentages
        const total = Array.from(languages.values()).reduce((a: number, b: number) => a + b, 0);
        const topLanguages = Array.from(languages.entries())
          .map(([name, bytes]: [string, number]) => ({
            name,
            percentage: Math.round((bytes / total) * 100),
            color: getLanguageColor(name)
          }))
          .sort((a, b) => b.percentage - a.percentage)
          .slice(0, 5);

        // Get top repositories
        const topRepos = repos
          .sort((a: any, b: any) => b.stargazers_count - a.stargazers_count)
          .slice(0, 3)
          .map((repo: any) => ({
            name: repo.name,
            stars: repo.stargazers_count,
            url: repo.html_url
          }));

        setStats({
          repoCount: repos.length,
          totalStars,
          contributions: 0, // You might want to fetch this from GitHub's GraphQL API
          pullRequests: 0, // You might want to fetch this separately
          topLanguages,
          topRepos
        });
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Failed to fetch GitHub stats');
        console.error('Error fetching GitHub stats:', err);
      } finally {
        setLoading(false);
      }
    };

    if (username && token) {
      fetchGitHubStats();
    } else {
      setError('GitHub username or token not configured');
    }
  }, [username, token]);

  if (loading) {
    return (
      <div className="card animate-pulse space-y-4">
        <div className="h-8 bg-theme-accent/10 rounded w-1/3"></div>
        <div className="grid grid-cols-2 gap-4">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="h-24 bg-theme-accent/10 rounded"></div>
          ))}
        </div>
        <div className="space-y-2">
          {[...Array(3)].map((_, i) => (
            <div key={i} className="h-4 bg-theme-accent/10 rounded"></div>
          ))}
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="card bg-red-500/10 border-red-500/20">
        <p className="text-red-400 font-tech">{error}</p>
      </div>
    );
  }

  if (!stats) return null;

  return (
    <div className="card backdrop-blur-sm bg-theme-card/50 relative overflow-hidden group">
      {/* Header */}
      <div className="flex items-center justify-between mb-6">
        <h3 className="font-cyber text-xl text-theme-accent">GitHub Metrics</h3>
        <a 
          href={`https://github.com/${username}`}
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center gap-2 text-sm font-tech text-theme-muted hover:text-theme-accent transition-colors"
        >
          @{username}
          <ExternalLink className="w-4 h-4" />
        </a>
      </div>

      {/* Stats Grid */}
      <div className="grid grid-cols-2 gap-4 mb-6">
        {[
          { icon: GitBranch, label: 'Repositories', value: stats.repoCount },
          { icon: Star, label: 'Total Stars', value: stats.totalStars },
          { icon: GitCommit, label: 'Top Language', value: stats.topLanguages[0]?.name || 'N/A' },
          { icon: GitPullRequest, label: 'Top Repo', value: stats.topRepos[0]?.name || 'N/A' }
        ].map(({ icon: Icon, label, value }) => (
          <div key={label} className="p-4 bg-theme-accent/5 rounded-lg hover:bg-theme-accent/10 transition-colors">
            <div className="flex items-center gap-2 mb-2">
              <Icon className="w-4 h-4 text-theme-accent" />
              <span className="text-xs font-tech text-theme-muted">{label}</span>
            </div>
            <div className="text-xl font-mono font-bold text-theme-text truncate">{value}</div>
          </div>
        ))}
      </div>

      {/* Language Stats */}
      <div className="space-y-3 mb-6">
        <h4 className="text-sm font-tech text-theme-muted">Languages</h4>
        <div className="space-y-2">
          {stats.topLanguages.map((lang) => (
            <div key={lang.name} className="space-y-1">
              <div className="flex justify-between text-xs">
                <span className="font-tech">{lang.name}</span>
                <span className="font-mono">{lang.percentage}%</span>
              </div>
              <div className="h-1.5 bg-theme-accent/20 rounded-full overflow-hidden">
                <div 
                  className="h-full rounded-full transition-all duration-500"
                  style={{ 
                    width: `${lang.percentage}%`,
                    backgroundColor: lang.color 
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Top Repositories */}
      <div className="space-y-3">
        <h4 className="text-sm font-tech text-theme-muted">Top Repositories</h4>
        <div className="space-y-2">
          {stats.topRepos.map((repo) => (
            <a
              key={repo.name}
              href={repo.url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center justify-between p-2 rounded-lg hover:bg-theme-accent/5 transition-colors"
            >
              <span className="font-tech text-sm">{repo.name}</span>
              <div className="flex items-center gap-1 text-theme-muted">
                <Star className="w-4 h-4" />
                <span className="font-mono text-sm">{repo.stars}</span>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

// Helper function to get language colors
const getLanguageColor = (language: string): string => {
  const colors: Record<string, string> = {
    JavaScript: '#f1e05a',
    TypeScript: '#3178c6',
    Python: '#3572A5',
    Java: '#b07219',
    Go: '#00ADD8',
    Ruby: '#701516',
    PHP: '#4F5D95',
    CSS: '#563d7c',
    HTML: '#e34c26',
    Vue: '#41b883',
    React: '#61dafb',
    Swift: '#ffac45',
    Kotlin: '#F18E33',
    Rust: '#dea584',
  };
  return colors[language] || '#6d28d9';
};

export default GitHubMetrics;