import { useState } from 'react'; // Added useState import
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, ChevronLeft, ChevronRight, 
    // ExternalLink 
} from 'lucide-react'; // Removed unused X import

interface Project {
  id: number | string;
  name: string;
  description: string;
  liveUrl?: string;
  githubUrl: string;
  technologies: string[];
  images: string[];
}

const PROJECT_GALLERIES: Record<string, Project> = {
  '1': {
  id: '1',
  name: 'DevOps-Centric MERN Stack Application with ML Integration',
  description: "A comprehensive MERN stack application with a strong focus on DevOps practices, showcasing CI/CD integration and cloud deployment.",
  images: ["/images/dq-arc.png", "/images/dq-pm2.png", "/images/dq-nginx.png", "/images/dq-cicd.png", "/images/dq-cloudf.png", "/images/dq-ssl.png", "/images/dq-actions.png"],
  liveUrl: 'https://DegreeQuest.stude-074.uk',
  githubUrl: 'https://github.com/example/mern-ml-integration',
  technologies: ["MERN", "DevOps", "CI/CD", "Google Cloud Platform", "GitHub Actions"],
},
'2': {
  id: '2',
  name: 'Full-Stack Portfolio Website with CI/CD',
  description: "This project showcases expertise in modern DevOps practices through the development of a full-stack portfolio website.",
    images: ["/images/port1.png", "/images/port2.png", "/images/port3.png", "/images/port4.png", "/images/port5.png", "/images/port6.png", "/images/port7.png", "/images/port8.png", "/images/portactions.png"],
  liveUrl: 'https://portfolio.example.com',
  githubUrl: 'https://github.com/example/portfolio-website',
  technologies: ["Next.js", "Flask", "Kubernetes", "GitHub Actions"],

},
'3': {
  id: '3',
  name: 'E-Commerce Application Deployment on AKS with Helm',
  description: "An e-commerce application deployed on AKS, showcasing microservices architecture and cloud deployment techniques.",
  images: ["/images/3tier1.png", "/images/3tier2.png"],
  liveUrl: 'https://ecommerce.example.com',
  githubUrl: 'https://github.com/example/ecommerce-aks',
  technologies: ["Kubernetes", "Helm", "Azure", "Microservices"],

},
'4': {
  id: '4',
  name: 'Arcane - Dynamic Blog Site',
  description: "A dynamic blog application emphasizing modern web development and cloud deployment practices with a focus on security.",
  images: ["/images/S1.png", "/images/S4.png", "/images/S7.png"],
  liveUrl: 'https://arcane-blog.example.com',
  githubUrl: 'https://github.com/example/arcane-blog',
  technologies: ["PHP", "Laravel", "Docker", "Google Cloud Platform"],

},
'5': {
  id: '5',
  name: 'Visitor Counter with Azure Function and API Management',
  description: "An Azure Function project that implements a visitor counter, showcasing the practical application of DevOps principles.",
  images: ["/images/1.png", "/images/2.png", "/images/3.png", "/images/4.png", "/images/5.png", "/images/5-1.png", "/images/5-2.png", "/images/6.png", "/images/7.png", "/images/9.png", "/images/9-1.png", "/images/9-2.png", "/images/11.png", "/images/13.png", "/images/14.png", "/images/15.png", "/images/16.png", "/images/17.png", "/images/19.png", "/images/20.png", "/images/20-1.png", "/images/21.png", "/images/22.png", "/images/23.png"],
  liveUrl: 'https://visitor-counter.example.com',
  githubUrl: 'https://github.com/example/visitor-counter',
  technologies: ["Azure Functions", "API Management", "Python", "Storage"],

},
'6': {
  id: '6',
  name: 'VM Backup Solution with AMIs and Rclone',
  description: "This project involves creating AMIs of virtual machines to meet backup requirements for clients. The AMIs are stored in an S3 bucket, and Rclone is configured to transfer backups from S3 to Google Drive, ensuring a reliable and efficient backup solution.",
  images: ["/images/backup1.png", "/images/backup2.png", "/images/backup3.png"],
  liveUrl: 'https://vmbackup.example.com',
  githubUrl: 'https://github.com/example/vm-backup-solution',
  technologies: ["AWS", "EC2", "S3", "Rclone", "Google Drive"],

}

  // ... other projects
};

const ProjectGallery = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0); // Added type annotation

  const projectData = PROJECT_GALLERIES[id || ''];

  if (!projectData) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-900 text-white">
        <div className="text-center">
          <h1 className="text-2xl font-bold mb-4">Project Not Found</h1>
          <button
            onClick={() => navigate('/')}
            className="text-blue-400 hover:text-blue-300 flex items-center gap-2 justify-center"
          >
            <ArrowLeft className="w-5 h-5" />
            <span>Go Back</span>
          </button>
        </div>
      </div>
    );
  }

  const nextImage = () => {
    setCurrentImageIndex((prev: number) => (prev + 1) % projectData.images.length); // Added type annotation
  };

  const previousImage = () => {
    setCurrentImageIndex((prev: number) => // Added type annotation
      prev === 0 ? projectData.images.length - 1 : prev - 1
    );
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white">
      {/* Header */}
      <header className="fixed top-0 w-full bg-gray-900/80 backdrop-blur-lg z-50">
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="flex items-center justify-between">
            <button
              onClick={() => navigate('/')}
              className="flex items-center gap-2 text-gray-300 hover:text-white transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
              <span>Back to Projects</span>
            </button>

            {/* <div className="flex items-center gap-4">
              {projectData.liveUrl && (
                <a
                  href={projectData.liveUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 hover:bg-blue-700 rounded-md transition-colors"
                >
                  <span>Live Demo</span>
                  <ExternalLink className="w-4 h-4" />
                </a>
              )}
            </div> */}
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="pt-20 pb-12 px-4">
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-bold mb-4 text-center">{projectData.name}</h1>
          <p className="text-gray-400 max-w-2xl mx-auto mb-8 text-center">
            {projectData.description}
          </p>

          {/* Technologies */}
          <div className="flex flex-wrap gap-2 mb-4">
              {projectData.technologies.map((tech) => (
                <span
                  key={tech}
                  className="px-3 py-1 text-sm bg-gray-800/50 rounded-full text-gray-300 hover:bg-gray-700/50 transition-colors duration-300"
                >
                  {tech}
                </span>
              ))}
            </div>

          {/* Image Gallery */}
          <div className="relative aspect-video bg-gray-800 rounded-lg overflow-hidden">
            <img
              src={projectData.images[currentImageIndex]}
              alt={`${projectData.name} screenshot ${currentImageIndex + 1}`}
              className="w-full h-full object-contain"
            />

            {/* Navigation Controls */}
            <div className="absolute inset-0 flex items-center justify-between px-4 opacity-0 hover:opacity-100 transition-opacity">
              <button
                onClick={previousImage}
                className="p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
                aria-label="Previous image"
              >
                <ChevronLeft className="w-6 h-6" />
              </button>
              <button
                onClick={nextImage}
                className="p-2 rounded-full bg-black/50 hover:bg-black/70 transition-colors"
                aria-label="Next image"
              >
                <ChevronRight className="w-6 h-6" />
              </button>
            </div>

            {/* Image Counter */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 px-4 py-2 rounded-full bg-black/50">
              {currentImageIndex + 1} / {projectData.images.length}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ProjectGallery;