// const AnimatedBackground = () => {
//     return (
//       <div className="fixed inset-0 -z-10 bg-theme-dark overflow-hidden">
//         {/* Grid pattern */}
//         <div className="absolute inset-0 bg-grid opacity-20" />
  
//         {/* Scanlines effect */}
//         <div className="absolute inset-0 scanlines" />
  
//         {/* Minimal floating orbs */}
//         <div className="absolute top-1/4 left-1/4 w-[600px] h-[600px] bg-cyan-500/10 rounded-full blur-3xl floating-slow" />
//         <div className="absolute bottom-1/4 right-1/4 w-[600px] h-[600px] bg-purple-500/10 rounded-full blur-3xl floating-delay" />
//       </div>
//     );
//   };
  
//   export default AnimatedBackground;


import { useEffect, useRef } from 'react';

const AnimatedBackground = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);

    let time = 0;
    const draw = () => {
      ctx.fillStyle = 'rgba(10, 10, 10, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      for (let i = 0; i < 3; i++) {
        ctx.beginPath();
        ctx.strokeStyle = `rgba(109, 40, 217, ${0.1 - i * 0.02})`;
        ctx.lineWidth = 2;
        
        for (let x = 0; x < canvas.width; x++) {
          const y = Math.sin(x * 0.003 + time + i) * 30 + canvas.height / 2;
          if (x === 0) {
            ctx.moveTo(x, y);
          } else {
            ctx.lineTo(x, y);
          }
        }
        
        ctx.stroke();
      }
      
      time += 0.01;
      requestAnimationFrame(draw);
    };

    draw();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);

  return (
    <div className="fixed inset-0 -z-10 bg-[#0a0a0a] overflow-hidden">
      <canvas 
        ref={canvasRef} 
        className="opacity-30"
        style={{ position: 'fixed', top: 0, left: 0 }}
      />
    </div>
  );
};



 export default AnimatedBackground;



