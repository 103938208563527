import { Github, Linkedin, Mail } from 'lucide-react';
import profilePic from '../assets/profile.jpg';
// import FavoriteSong from './FavoriteSong';

const Hero = () => {
  return (
    <section className="card">
      <div className="space-y-4">
        <div className="flex flex-col md:flex-row gap-6 md:items-center">
          <div className="relative">
            {/* Profile Image */}
            <div className="w-24 h-24 md:w-32 md:h-32 rounded-2xl overflow-hidden relative z-10">
              <img
                src={profilePic}
                alt="Profile"
                className="w-full h-full object-cover object-center"
              />
              {/* Decorative elements */}
              <div className="absolute -inset-0.5 bg-gradient-to-r from-yellow-500 to-pink-600 opacity-30 animate-pulse blur-sm rounded-2xl -z-10" />
            </div>
            {/* Background decorative element */}
            <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 opacity-20 blur-2xl rounded-3xl -z-10" />
          </div>

          <div className="space-y-1">
            {/* Name Section */}
            <div className="relative">
              <h1 className="font-name text-3xl md:text-4xl font-bold tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-white to-gray-200">
                Mudassir Ali
              </h1>
              <div className="absolute -bottom-1 left-0 h-px w-24 bg-gradient-to-r from-purple-600 to-transparent" />
            </div>
            
            {/* Title */}
            <h2 className="font-cyber text-sm md:text-base font-bold tracking-wider uppercase text-theme-accent/90">
              DevOps & Full Stack Engineer
            </h2>
            <p className="font-tech text-theme-muted tracking-wide text-sm">
              Building scalable solutions & automating everything
            </p>
          </div>
        </div>
        
        <p className="font-tech text-theme-text/80 leading-relaxed tracking-wide">
          Hi there! I'm a DevOps engineer and full-stack developer passionate about creating 
          efficient systems and beautiful applications. I specialize in cloud infrastructure, 
          CI/CD pipelines, and modern web development.
        </p>
        
        <div className="flex flex-wrap gap-4">
          {/* <button className="hover-glow px-4 py-2 bg-theme-accent rounded-lg font-cyber uppercase tracking-widest text-sm">
            View Projects
          </button> */}
          
          <div className="flex gap-2">
            <a 
              href="https://github.com/mudas2002" 
              className="p-2 rounded-lg hover-glow"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Github className="w-5 h-5" />
            </a>
            <a 
              href="https://linkedin.com/in/mud2002" 
              className="p-2 rounded-lg hover-glow"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Linkedin className="w-5 h-5" />
            </a>
            <a 
              href="mailto:mudassir45ali@outlook.com" 
              className="p-2 rounded-lg hover-glow"
            >
              <Mail className="w-5 h-5" />
            </a>
          </div>
        </div>

        {/* <div className="mt-8">
          <FavoriteSong />
        </div> */}
      </div>
    </section>
  );
};

export default Hero;