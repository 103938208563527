import { 
  Github, Linkedin, Mail, Twitter, FileText } from 'lucide-react';

interface SocialLink {
  icon: React.ComponentType<{ className?: string }>;
  url: string;
  color: string;
  label: string;
}

const socialLinks: SocialLink[] = [
  {
    icon: Github,
    url: 'https://github.com/mudas2002',
    color: 'hover:text-[#333]',
    label: 'GitHub'
  },
  {
    icon: Linkedin,
    url: 'https://linkedin.com/in/mud2002',
    color: 'hover:text-[#0077b5]',
    label: 'LinkedIn'
  },
  {
    icon: Twitter,
    url: 'https://twitter.com/al85527',
    color: 'hover:text-[#1DA1F2]',
    label: 'Twitter'
  },
  {
    icon: Mail,
    url: 'mailto:mudassir45ali@outlook.com.com',
    color: 'hover:text-[#EA4335]',
    label: 'Email'
  },
  // {
  //   icon: Globe,
  //   url: '/blog',
  //   color: 'hover:text-[#00ab6c]',
  //   label: 'Blog'
  // },
  {
    icon: FileText,
    url: '/resume',
    color: 'hover:text-[#FFB800]',
    label: 'Resume'
  }
];

const SocialLinks = () => {
  return (
    <>
      {/* Mobile Bottom Bar */}
      <div className="fixed md:hidden bottom-0 left-0 right-0 bg-theme-dark/80 backdrop-blur-sm p-4">
        <div className="flex justify-around max-w-sm mx-auto">
          {socialLinks.map((link) => (
            <SocialIcon key={link.label} {...link} />
          ))}
        </div>
      </div>

      {/* Desktop Side Bar */}
      <div className="hidden md:block fixed left-4 top-1/2 -translate-y-1/2 space-y-4">
        {socialLinks.map((link) => (
          <SocialIcon key={link.label} {...link} />
        ))}
      </div>
    </>
  );
};

// Separated SocialIcon component for cleaner code
const SocialIcon = ({ icon: IconComponent, url, color, label }: SocialLink) => {
  return (
    <a
      href={url}
      className={`group relative p-2 rounded-xl hover:bg-white/10 transition-all duration-300 
        md:flex md:items-center ${color}`}
      target={url.startsWith('http') ? '_blank' : undefined}
      rel={url.startsWith('http') ? 'noopener noreferrer' : undefined}
      aria-label={label}
    >
      <IconComponent className="w-6 h-6 transition-all duration-300 group-hover:scale-110" />
      <span className="absolute -top-8 left-1/2 -translate-x-1/2 md:relative md:top-0 md:left-0 md:translate-x-0
        md:ml-3 bg-theme-card/90 md:bg-theme-dark/90 px-2 py-1 rounded text-xs md:text-sm
        opacity-0 group-hover:opacity-100 transition-all duration-300 whitespace-nowrap
        pointer-events-none">
        {label}
      </span>
    </a>
  );
};

export default SocialLinks;