import { useState, useEffect } from 'react';
import { Terminal } from 'lucide-react';

const quotes = [
  { text: "There are 10 types of people in this world...", author: "Binary Humor" },
  { text: "Have you tried turning it off and on again?", author: "IT Wisdom" },
  { text: "It's not a bug, it's an undocumented feature", author: "Every Developer Ever" },
  { text: "To (){ To();} // To infinity and beyond!", author: "Recursion" },
  { text: "home/sweet/home", author: "Unix Path" },
  { text: "Sleep is for the weak. Coffee is for the strong.", author: "DevOps Life" }
];

const TechQuote = () => {
  const [currentQuote, setCurrentQuote] = useState(0);
  const [isTyping, setIsTyping] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setIsTyping(true);
      setCurrentQuote((prev) => (prev + 1) % quotes.length);
    }, 8000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTyping(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [currentQuote]);

  return (
    <div className="card bg-theme-card/50 backdrop-blur-sm relative overflow-hidden group mx-auto">
      <div className="absolute inset-0 bg-gradient-to-r from-theme-accent/5 via-transparent to-theme-accent/5 animate-gradient" />
      
      <div className="relative p-4">
        <div className="flex items-center gap-2 mb-3">
          <Terminal className="w-4 h-4 text-theme-accent" />
          <span className="text-xs font-mono text-theme-accent">stdout</span>
          <div className={`w-2 h-2 rounded-full ${isTyping ? 'bg-green-500 animate-pulse' : 'bg-theme-muted'}`} />
        </div>

        <div className="space-y-2">
          <p className="font-mono text-sm sm:text-base">
            <span className="text-theme-accent">$ echo </span>
            <span className="text-theme-text">
              {quotes[currentQuote].text}
            </span>
          </p>
          <p className="font-mono text-xs text-theme-muted">
            ~ {quotes[currentQuote].author}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TechQuote;