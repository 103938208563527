
// import Hero from './components/Hero'

// import ThoughtOfWeek from './components/ThoughtOfWeek'
// import SocialLinks from './components/SocialLinks'
// import AnimatedBackground from './components/AnimatedBackground'
// import FavoriteSong from './components/FavoriteSong'
// import ContactForm from './components/ContactForm'
// import GitHubMetrics from './components/GitHubMetrics'
// import TechQuote from './components/TechQuote'
// import ProjectShowcase from './components/ProjectShowcase'

// function App() {
//   return (
//     <div className="min-h-screen relative">
//       <AnimatedBackground />
      
//       <main className="container mx-auto px-4 py-6 md:py-12 max-w-3xl lg:max-w-4xl relative">
//         <div className="space-y-6 md:space-y-12 pb-24 md:pb-0">
//           <Hero />

//           <ProjectShowcase/>

//           <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
//             <FavoriteSong />
//             <TechQuote/>
//           </div>

//           <ThoughtOfWeek />
//           <ContactForm/>
//           <GitHubMetrics/>
//         </div>
        
//         <SocialLinks />
//       </main>
//     </div>
//   );
// }

// export default App;


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './components/Hero'
import ThoughtOfWeek from './components/ThoughtOfWeek'
import SocialLinks from './components/SocialLinks'
import AnimatedBackground from './components/AnimatedBackground'
import FavoriteSong from './components/FavoriteSong'
import ContactForm from './components/ContactForm'
import GitHubMetrics from './components/GitHubMetrics'
import TechQuote from './components/TechQuote'
import ProjectShowcase from './components/ProjectShowcase'
import ProjectGallery from './routes/project/[id]/gallery'

function App() {
  return (
    <Router>
      <div className="min-h-screen relative">
        <AnimatedBackground />
        <Routes>
          <Route path="/" element={
            <main className="container mx-auto px-4 py-6 md:py-12 max-w-3xl lg:max-w-4xl relative">
              <div className="space-y-6 md:space-y-12 pb-24 md:pb-0">
                <Hero />
                <ProjectShowcase/>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
                  <FavoriteSong />
                  <TechQuote/>
                </div>
                <ThoughtOfWeek />
                <ContactForm/>
                <GitHubMetrics/>
              </div>
              <SocialLinks />
            </main>
          } />
          <Route path="/project/:id/gallery" element={<ProjectGallery />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;