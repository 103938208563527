import { Music } from 'lucide-react';
import { useEffect, useState } from 'react';

interface SongInfo {
  artworkUrl: string;
  title: string;
  artist: string;
}

const FavoriteSong = () => {
  const [songInfo, setSongInfo] = useState<SongInfo | null>(null);
  const [loading, setLoading] = useState(true);

  // You can change these to your current favorite song
  const songTitle = "A Thousand Years";
  const songArtist = "Sting";

  useEffect(() => {
    const fetchSongInfo = async () => {
      try {
        const response = await fetch(
          `https://itunes.apple.com/search?term=${encodeURIComponent(
            `${songTitle} ${songArtist}`
          )}&entity=song&limit=1`
        );
        const data = await response.json();
        
        if (data.results && data.results[0]) {
          setSongInfo({
            artworkUrl: data.results[0].artworkUrl100.replace('100x100', '300x300'),
            title: songTitle,
            artist: songArtist
          });
        }
      } catch (error) {
        console.error('Failed to fetch song info:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSongInfo();
  }, []);

  return (
    <div className="cyber-card p-4 hover-glow max-w-xs mx-auto">
      <div className="flex items-center space-x-3 mb-3">
        <div className="w-8 h-8 rounded-full bg-theme-accent/20 flex items-center justify-center">
          <Music className="w-4 h-4 text-theme-accent" />
        </div>
        <h3 className="font-tech text-sm text-theme-muted">Song of the Week</h3>
      </div>

      {loading ? (
        <div className="animate-pulse">
          <div className="w-16 h-16 bg-theme-accent/20 rounded-lg"></div>
          <div className="h-4 bg-theme-accent/20 rounded mt-3 w-2/3"></div>
          <div className="h-3 bg-theme-accent/20 rounded mt-2 w-1/2"></div>
        </div>
      ) : songInfo ? (
        <div className="flex items-center space-x-4">
          <div className="relative group">
            <img 
              src={songInfo.artworkUrl} 
              alt={`${songInfo.title} album art`}
              className="w-16 h-16 rounded-lg object-cover transform transition-all duration-300 group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-gradient-to-br from-purple-500/10 to-pink-500/10 rounded-lg group-hover:opacity-0 transition-opacity" />
          </div>
          
          <div>
            <h4 className="font-tech font-medium text-theme-text truncate">
              {songInfo.title}
            </h4>
            <p className="text-sm text-theme-muted truncate">
              {songInfo.artist}
            </p>
          </div>
        </div>
      ) : (
        <div className="text-theme-muted text-sm">
          Failed to load song info
        </div>
      )}
    </div>
  );
};

export default FavoriteSong;