import { Calendar } from 'lucide-react';

const ThoughtOfWeek = () => {
  const currentThought = {
    content: "Infrastructure as Code isn't just about automation - it's about creating a single source of truth for your entire system architecture.",
    date: new Date().toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }),
    tags: ['DevOps', 'Infrastructure', 'Best Practices']
  };

  return (
    <section className="card space-y-4">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold gradient-text">Thought of the Week</h2>
        <div className="flex items-center text-sm text-theme-muted">
          <Calendar className="w-4 h-4 mr-2" />
          {currentThought.date}
        </div>
      </div>

      <blockquote className="text-lg italic text-theme-text/90 border-l-2 border-theme-accent pl-4">
        "{currentThought.content}"
      </blockquote>

      <div className="flex flex-wrap gap-2">
        {currentThought.tags.map(tag => (
          <span 
            key={tag}
            className="px-2 py-1 text-xs rounded-full bg-theme-accent/10 text-theme-accent"
          >
            #{tag}
          </span>
        ))}
      </div>
    </section>
  );
};

export default ThoughtOfWeek;