import React, { useState } from 'react';
import { Send, Loader } from 'lucide-react';

interface FormState {
  email: string;
  message: string;
}

const ContactForm = () => {
  const [formData, setFormData] = useState<FormState>({
    email: '',
    message: ''
  });
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

  // In your handleSubmit function:
const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setStatus('loading');
    
    try {
      const response = await fetch('https://my-portfolio-production-d3f2.up.railway.app/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include' // Add this line
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to send message');
      }
      
      setStatus('success');
      setFormData({ email: '', message: '' });
      
      setTimeout(() => {
        setStatus('idle');
      }, 5000);
      
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('error');
      
      setTimeout(() => {
        setStatus('idle');
      }, 5000);
    }
  };

  return (
    <div className="relative">
      {/* Decorative background */}
      <div className="absolute -inset-1 bg-gradient-to-r from-purple-600/20 to-pink-600/20 rounded-lg blur-lg" />
      
      <form onSubmit={handleSubmit} className="relative bg-theme-card/95 backdrop-blur-xl rounded-lg p-6 border border-white/10">
        <h2 className="font-cyber text-xl mb-6 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
          Let's Connect
        </h2>

        <div className="space-y-4">
          {/* Email Input */}
          <div className="group relative">
            <input
              type="email"
              value={formData.email}
              onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
              placeholder="your@email.com"
              required
              className="w-full bg-theme-dark/50 border border-white/10 rounded-lg px-4 py-3 
                focus:outline-none focus:border-theme-accent/50 focus:ring-1 focus:ring-theme-accent/50
                transition-all duration-300 font-tech placeholder:text-theme-muted"
            />
            <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-purple-600/20 to-pink-600/20 opacity-0 
              group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />
          </div>

          {/* Message Input */}
          <div className="group relative">
            <textarea
              value={formData.message}
              onChange={(e) => setFormData(prev => ({ ...prev, message: e.target.value }))}
              placeholder="Your message..."
              required
              rows={4}
              className="w-full bg-theme-dark/50 border border-white/10 rounded-lg px-4 py-3 
                focus:outline-none focus:border-theme-accent/50 focus:ring-1 focus:ring-theme-accent/50
                transition-all duration-300 font-tech placeholder:text-theme-muted resize-none"
            />
            <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-purple-600/20 to-pink-600/20 opacity-0 
              group-hover:opacity-100 transition-opacity duration-300 pointer-events-none" />
          </div>

          {/* Submit Button */}
          <button
            type="submit"
            disabled={status === 'loading'}
            className="w-full bg-theme-accent hover:bg-theme-accent/80 text-white font-cyber 
              px-6 py-3 rounded-lg transition-all duration-300 flex items-center justify-center
              space-x-2 hover:scale-[1.02] disabled:opacity-50 disabled:cursor-not-allowed
              disabled:hover:scale-100 group relative overflow-hidden"
          >
            <div className="absolute inset-0 bg-gradient-to-r from-purple-600/20 to-pink-600/20 opacity-0 
              group-hover:opacity-100 transition-opacity duration-300" />
            
            <div className="relative flex items-center space-x-2">
              {status === 'loading' ? (
                <>
                  <Loader className="w-4 h-4 animate-spin" />
                  <span>Sending...</span>
                </>
              ) : (
                <>
                  <Send className="w-4 h-4" />
                  <span>Send Message</span>
                </>
              )}
            </div>
          </button>
        </div>

        {/* Status Messages */}
        <div className="mt-4 text-center font-tech text-sm">
          {status === 'success' && (
            <div className="text-green-400 flex items-center justify-center space-x-2">
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
              <span>Message sent successfully!</span>
            </div>
          )}
          {status === 'error' && (
            <div className="text-red-400 flex items-center justify-center space-x-2">
              <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
              <span>Failed to send message. Please try again.</span>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default ContactForm;